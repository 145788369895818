<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">

      <v-row>
        <v-col>
          <v-btn
            color="info"
            class="mr-2"
            dark
            outlined
            @click="showDialogCitiesIndexesImport = true"
          >
            <v-icon
              left
              v-text="'mdi-import'"
            />
            Importar Índices de Repasse
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            label="Pesquisar"
            single-line
            filled
            dense
            hide-details
            clearable
            prepend-inner-icon="mdi-magnify"
          />
          <v-sheet elevation="1">
            <v-data-table
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="cities"
              :options.sync="pagination"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 75]
              }"
              @update:options="loadCities"
            >
              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  @click="openDialogEditContacts(item)"
                >
                  <v-icon
                    small
                  >
                    mdi-card-account-mail
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="openDialogEditIndexes(item)"
                >
                  <v-icon
                    small
                  >
                    mdi-percent
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-cities-indexes
      v-model="showDialogEditCityIndexes"
      :city-id="editCityIndexId"
    />

    <dialog-cities-indexes-import
      v-model="showDialogCitiesIndexesImport"
    />

    <dialog-cities-contacts
      v-model="showDialogEditCityContacts"
      :city-id="editCityContactId"
    />
  </div>
</template>

<script>
  import citiesApi from '@/api/cities'

  export default {

    components: {
      DialogCitiesIndexes: () => import('@/components/dialog/cities-indexes/DialogCitiesIndexes'),
      DialogCitiesIndexesImport: () => import('@/components/dialog/cities-indexes/DialogCitiesIndexesImport'),
      DialogCitiesContacts: () => import('@/components/dialog/cities-contacts/DialogCitiesContacts'),
    },

    data () {
      return {
        loading: false,
        search: null,
        count: 0,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Nome', value: 'name' },
          { text: '', value: 'actions', align: 'right' },
        ],
        cities: [],
        pagination: {},
        showDialogEditCityIndexes: false,
        showDialogEditCityContacts: false,
        showDialogCitiesIndexesImport: false,
        editCityIndexId: null,
        editCityContactId: null,
      }
    },

    watch: {
      search () {
        this.pagination.page = 1
        this.loadCities()
      },
    },

    methods: {
      async loadCities () {
        try {
          this.loading = true

          const response = await citiesApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
            name: this.search || '',
          })

          this.cities = response.data.cities
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialogEditIndexes (city) {
        this.showDialogEditCityIndexes = true
        this.editCityIndexId = city.id
      },

      openDialogEditContacts (city) {
        this.showDialogEditCityContacts = true
        this.editCityContactId = city.id
      },

    },

  }
</script>
